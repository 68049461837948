import { Card } from '@yleisradio/areena-types';
import { Time } from 'components/Time';
import { findLabel } from 'utils/card';
import styles from './RadioChannelCardContent.module.scss';

type Props = {
  ongoingCard: Card | undefined;
  upcomingCard: Card | undefined;
};

const RadioChannelCardContent = ({ ongoingCard, upcomingCard }: Props) => {
  return (
    <div className={styles.textContainer}>
      <p className={styles.ongoing}>
        <Time
          date={findLabel(ongoingCard?.labels, 'broadcastStartDate')?.raw}
        />{' '}
        {ongoingCard?.title}
      </p>
      <p className={styles.upcoming}>
        <Time
          date={findLabel(upcomingCard?.labels, 'broadcastStartDate')?.raw}
        />{' '}
        {upcomingCard?.title}
      </p>
    </div>
  );
};

export { RadioChannelCardContent };
