import { useUILanguage } from 'hooks/useUILanguage';
import React from 'react';
import { timeZone } from 'utils/time';

function toDate(date: Date | string): Date {
  const dateObject = new Date(date);

  if (isNaN(dateObject.valueOf())) throw new Error(`Date is invalid: ${date}`);

  return dateObject;
}

type Props = {
  date: Date | string | undefined;
};

export const Time: React.FC<Props> = ({ date }) => {
  const language = useUILanguage();

  if (!date) return null;

  const isoDate = typeof date === 'string' ? date : date.toISOString();
  const formattedTime = toDate(date).toLocaleTimeString(language, {
    timeStyle: 'short',
    timeZone,
  });

  return <time dateTime={isoDate}>{formattedTime}</time>;
};
