import { useCallback, useEffect, useState } from 'react';
import { YlePlayer } from 'types/player-static';
import {
  PlaybackRateGetter,
  PlaybackRateSetter,
  PlaybackRatesGetter,
} from 'components/Player/AudioPlayerWrapper/typesAudioPlayerWrapper';
import logger from 'services/logger';

export function useIsExpandedPlayerAvailable(): boolean {
  const [state, setState] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('screen and (min-width: 800px)');

    setState(!mediaQuery.matches);

    const onChange = (e: MediaQueryListEvent) => {
      setState(!e.matches);
    };

    mediaQuery.addEventListener('change', onChange);

    return () => {
      mediaQuery.removeEventListener('change', onChange);
    };
  }, []);

  return state;
}

export const useAudioPlayerProgression = (
  playerInstanceRef: React.RefObject<YlePlayer | undefined>
) => {
  const [progression, setProgression] = useState(0);

  const audioSeek = useCallback(
    (position: number) => {
      playerInstanceRef.current?.seek?.(position);
      setProgression(position);
    },
    [playerInstanceRef]
  );

  const handleOnTimeUpdate = useCallback((time: number) => {
    const position = Math.floor(time);

    setProgression(position);
  }, []);

  return { progression, audioSeek, handleOnTimeUpdate };
};

export const useAudioPlayerVolume = (
  playerInstanceRef: React.RefObject<YlePlayer | undefined>
): {
  isMuted: boolean;
  setMute: (mute: boolean) => void;
  volume: number;
  setVolume: (volume: number) => void;
} => {
  const [muteState, setMuteState] = useState(false);
  const [volumeState, setVolumeState] = useState(1);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    try {
      const storageValue = window.localStorage.getItem('PlayerUI:settings');

      if (!storageValue) return;

      const playerSettings = JSON.parse(storageValue);

      if (typeof playerSettings.volume === 'number') {
        setVolumeState(playerSettings.volume);
      }
      if (playerSettings.muted) {
        setMuteState(true);
      }
    } catch (error) {
      logger.error(
        error,
        'Cannot read playerUI volume value from local storage'
      );
    }
  }, []);

  const setMute = useCallback(
    (mute: boolean): void => {
      if (!playerInstanceRef.current)
        throw new Error(
          "Can't set mute state because playerInstance is undefined"
        );

      setMuteState(mute);

      if (mute) {
        playerInstanceRef.current.mute();
      } else {
        playerInstanceRef.current.unmute();
      }
    },
    [playerInstanceRef]
  );

  const setVolume = useCallback(
    (volume: number): void => {
      if (!playerInstanceRef.current)
        throw new Error("Can't set volume because playerInstance is undefined");

      setVolumeState(volume);
      playerInstanceRef.current.volume(volume);
    },
    [playerInstanceRef]
  );

  return {
    isMuted: muteState,
    setMute,
    volume: volumeState,
    setVolume,
  };
};

export const usePlaybackRate = (
  playerInstanceRef: React.RefObject<YlePlayer | undefined>
) => {
  const [currentPlaybackRate, setCurrentPlaybackRate] = useState<number>(1);

  const setPlaybackRate: PlaybackRateSetter = useCallback(
    (rate: number) => {
      if (!playerInstanceRef.current) {
        throw new Error(
          "Can't set playback rate because playerInstance is undefined"
        );
      }

      playerInstanceRef.current.setPlaybackRate(rate);
      setCurrentPlaybackRate(rate);
    },
    [playerInstanceRef]
  );

  const getPlaybackRate: PlaybackRateGetter = () => {
    return currentPlaybackRate;
  };

  const getPlaybackRates: PlaybackRatesGetter = () => {
    return playerInstanceRef.current?.playbackRates();
  };

  return {
    getPlaybackRates,
    setPlaybackRate,
    getPlaybackRate,
  };
};
