import * as RadixDialog from '@radix-ui/react-dialog';
import React, { PropsWithChildren, useRef } from 'react';

type Props = {
  isOpen: boolean;
  className: string | undefined;
  onOpenChange: (open: boolean) => void;
};

/** Include DialogTitle component in Dialog's children */
export const Dialog: React.FunctionComponent<PropsWithChildren<Props>> = (
  props
) => {
  const triggerElementRef = useRef<HTMLElement>(undefined);

  return (
    <RadixDialog.Root
      open={props.isOpen}
      onOpenChange={(open) => props.onOpenChange && props.onOpenChange(open)}
    >
      <RadixDialog.Portal>
        <RadixDialog.Content
          aria-describedby={undefined}
          className={props.className}
          onOpenAutoFocus={() => {
            const triggerElement = window.document.activeElement;

            if (triggerElement instanceof HTMLElement)
              triggerElementRef.current = triggerElement;
          }}
          onCloseAutoFocus={(e) => {
            const triggerElement = triggerElementRef.current;

            if (triggerElement) {
              triggerElement.focus();
              e.preventDefault();
            } else {
              throw new Error(
                'Missing reference to the element that opened the dialog'
              );
            }
          }}
          onEscapeKeyDown={(event) => {
            event.preventDefault();
          }}
        >
          {props.children}
        </RadixDialog.Content>
      </RadixDialog.Portal>
    </RadixDialog.Root>
  );
};

export const DialogTitle = RadixDialog.Title;
