import {
  Announcements,
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DialogTitle } from 'components/Dialog/Dialog';
import CurrentlyPlaying from 'components/Player/AudioPlayerWrapper/PlayQueue/CurrentlyPlaying';
import LoginPromotion from 'components/Player/AudioPlayerWrapper/PlayQueue/LoginPromotion';
import NextInQueueList from 'components/Player/AudioPlayerWrapper/PlayQueue/NextInQueueList';
import NextInSeriesList from 'components/Player/AudioPlayerWrapper/PlayQueue/NextInSeriesList';
import styles from 'components/Player/AudioPlayerWrapper/PlayQueue/PlayQueue.module.scss';
import { usePlayerState } from 'contexts/PlayerStateContext';
import { useTunnusContext } from 'contexts/TunnusContext';
import { useTranslation } from 'hooks/useTranslation';
import { useState } from 'react';
import { UserQueueItem } from 'contexts/PlayerStateContext/UserQueueItem';
import NextInRecommendationsList from './NextInRecommendationsList/NextInRecommendationsList';
import { ToggleQueueCardsPrompt } from './ToggleQueueCardsPrompt/ToggleQueueCardsPrompt';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { QueueItemData } from './types';
import { QueueItem } from 'contexts/PlayerStateContext/QueueItem';

type UpdateOperation = 'add' | 'remove';

type Props = {
  close: () => void;
};

const removeQueueItem = (
  prevState: UserQueueItem[],
  selectedQueueItemKeys: string[]
): UserQueueItem[] => {
  const newState = prevState.filter((item) => {
    return !selectedQueueItemKeys.includes(item.key);
  });
  return newState;
};

const addQueueItem = (
  prevState: UserQueueItem[],
  selectedQueueItemKeys: string[],
  seriesQueue: QueueItem[],
  recommendationsQueue: QueueItem[]
): UserQueueItem[] => {
  // Copy in the same order as the items appear
  const newQueueItems = [...prevState, ...seriesQueue, ...recommendationsQueue]
    .filter((item) => selectedQueueItemKeys.includes(item.key))
    .map(UserQueueItem.createFromQueue);

  return [...prevState, ...newQueueItems];
};

export function PlayQueue({ close }: Props) {
  const {
    queue,
    recommendationsQueue,
    recommendationsAnalyticsContext,
    seriesQueue,
    seriesAnalyticsContext,
    showRecommendationsQueue,
    setShowRecommendationsQueue,
    setQueue,
  } = usePlayerState();
  const t = useTranslation();
  const { isAuthenticated } = useTunnusContext();

  const [selectedQueueItemKeys, setSelectedQueueItemKeys] = useState<string[]>(
    []
  );

  const showQueueControlPrompt = selectedQueueItemKeys.length > 0;

  /* design decision: if series cards chekboxes are clicked, remove button from prompt
   is disabled for all to keep series queue intact */
  const isRemoveQueueCardsButtonDisabled = selectedQueueItemKeys.some(
    (key) => !queue.some((item) => item.key === key)
  );

  const updatePlayQueueItems = (operationType: UpdateOperation) => {
    if (operationType == 'remove') {
      setQueue(removeQueueItem(queue, selectedQueueItemKeys));
    } else if (operationType == 'add') {
      setQueue(
        addQueueItem(
          queue,
          selectedQueueItemKeys,
          seriesQueue,
          recommendationsQueue
        )
      );
    } else {
      return;
    }

    setSelectedQueueItemKeys([]); //reset user queue ui cards
  };

  const removeAllPlayQueueItems = () => {
    setQueue([]);
  };

  const handleQueueCheckboxChange = (checked: boolean, key: string) => {
    if (checked) {
      setSelectedQueueItemKeys([...selectedQueueItemKeys, key]);
    } else {
      setSelectedQueueItemKeys(
        selectedQueueItemKeys.filter((currentKey) => currentKey !== key)
      );
    }
  };

  const handleSetShowRecommendationsQueue = (value: boolean) => {
    setShowRecommendationsQueue(value);
    setSelectedQueueItemKeys((current) =>
      current.filter(
        (key) => !recommendationsQueue.some((item) => item.key === key)
      )
    );
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const announcements: Announcements = {
    onDragStart({ active }): string {
      const itemTitle =
        (active.data.current as QueueItemData | undefined)?.textContent || '';
      const itemPosition =
        queue.findIndex((item) => item.key === active.id) + 1;

      return t('queueItemDragStart', {
        title: itemTitle,
        position: itemPosition,
        count: queue.length,
      });
    },
    onDragOver({ active, over }): string {
      const itemTitle =
        (active.data.current as QueueItemData | undefined)?.textContent || '';
      const newPosition = over
        ? queue.findIndex((item) => item.key === over.id) + 1
        : 0;

      return t('queueItemDragOver', {
        title: itemTitle,
        position: newPosition,
        count: queue.length,
      });
    },
    onDragEnd({ active, over }): string {
      if (!over) return t('queueItemDragCancel');

      const itemTitle =
        (active.data.current as QueueItemData | undefined)?.textContent || '';
      const newPosition = queue.findIndex((item) => item.key === over.id) + 1;

      return t('queueItemDragEnd', {
        title: itemTitle,
        position: newPosition,
        count: queue.length,
      });
    },
    onDragCancel: (): string => t('queueItemDragCancel'),
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    // dnd id's are queue item keys
    if (active && over && active.id !== over.id) {
      const oldIndex = queue.findIndex((item) => item.key === active.id);
      const newIndex = queue.findIndex((item) => item.key === over.id);

      setQueue(arrayMove(queue, oldIndex, newIndex));
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <DialogTitle asChild>
          <h2 className={styles.title}>{t('queue')}</h2>
        </DialogTitle>
      </div>
      {isAuthenticated && (
        <>
          <CurrentlyPlaying closeQueuePopover={close} />
          {queue.length > 0 && (
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={handleDragEnd}
              sensors={sensors}
              accessibility={{
                announcements,
                screenReaderInstructions: {
                  draggable: t('draggableInstructions'),
                },
              }}
            >
              <SortableContext
                items={queue.map((item) => item.key)}
                strategy={verticalListSortingStrategy}
              >
                <NextInQueueList
                  items={queue}
                  setItemSelection={handleQueueCheckboxChange}
                  selectedItemKeys={selectedQueueItemKeys}
                  removeAllQueueItems={removeAllPlayQueueItems}
                />
              </SortableContext>
            </DndContext>
          )}
          {seriesQueue.length > 0 && (
            <div className={styles.container}>
              <NextInSeriesList
                seriesQueue={seriesQueue}
                analyticsContext={seriesAnalyticsContext}
                setItemSelection={handleQueueCheckboxChange}
                selectedItemKeys={selectedQueueItemKeys}
              />
            </div>
          )}
          {recommendationsQueue.length > 0 && (
            <div className={styles.container}>
              <NextInRecommendationsList
                recommendationsQueue={recommendationsQueue}
                analyticsContext={recommendationsAnalyticsContext}
                showRecommendationsQueue={showRecommendationsQueue}
                setShowRecommendationsQueue={handleSetShowRecommendationsQueue}
                selectedItemKeys={selectedQueueItemKeys}
                setItemSelection={handleQueueCheckboxChange}
              />
            </div>
          )}
          {showQueueControlPrompt && (
            <ToggleQueueCardsPrompt
              isRemoveDisabled={isRemoveQueueCardsButtonDisabled}
              updateItems={updatePlayQueueItems}
            />
          )}
        </>
      )}
      {!isAuthenticated && <LoginPromotion />}
    </div>
  );
}
